// SCROLL TO ID & #REMOVAL

$(function() {
  $('a[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1500);
        return false;
      }
    }
  });
});

// SCROLL REVEAL.JS

var startScrollReveal = function(){

	window.sr = ScrollReveal({ 
	
	  reset:        false, 
	  mobile:       false, 
	  viewFactor:   0,
	  duration:     800,
	  origin:       'left',
	  distance:     '50px',
	
	});
	
	// VIEWPORT DELAY
	
	sr.reveal('h1', {reset: false, distance:'0px', delay: 0});
	sr.reveal('.burger-menu', {reset: false, distance:'0px', delay: 0});
	sr.reveal('.nav', {distance:'0px', delay: 0});
	sr.reveal('.infos', {distance:'0px', delay: 0});
	
	sr.reveal('h2', {delay: 50});
	sr.reveal('.section-inner', {delay: 50});
	sr.reveal('h3', {delay: 50});
	//sr.reveal('h4', {delay: 100});
	sr.reveal('p:not(.category-description cookie-bar-p)', {delay: 150});
	sr.reveal('.next', {delay: 250});
	sr.reveal('.cta:not(.cta-inner)', {delay: 300});
	
	sr.reveal('ul.content-container-films li', {delay: 300});
	sr.reveal('.breadcrumb', {delay: 500});

};

//
$(document).ready(function() {
	if($(window).width() > 411) {
		var myFullpage = new fullpage('#fullpage', {
		  //Navigation
		  licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
		  menu: 'none',
		  lockAnchors: false,
		  anchors: quickMenu,
		  //anchors:['Home', 'Scope_Pictures','Our_Expertise','What_is_this','Filmography','Contact'],
		  
		  //Scrolling
		  css3: true,
		  scrollingSpeed: 700,
		  autoScrolling: true,
		  fitToSection: true,
		  fitToSectionDelay: 1000,
		
		  scrollBar: true,
		  easing: 'easeInOutCubic',
		  easingcss3: 'ease',
		  loopBottom: false,
		  loopTop: false,
		  loopHorizontal: true,
		  continuousVertical: false,
		  continuousHorizontal: false,
		  scrollHorizontally: false,
		  interlockedSlides: false,
		  dragAndMove: false,
		  offsetSections: false,
		  resetSliders: false,
		  fadingEffect: false,
		  normalScrollElements: '#element1, .element2',
		  scrollOverflow: false,
		  scrollOverflowReset: false,
		  scrollOverflowOptions: null,
		  touchSensitivity: 15,
		  normalScrollElementTouchThreshold: 5,
		  bigSectionsDestination: null,
		
		  //Accessibility
		  keyboardScrolling: true,
		  animateAnchor: true,
		  recordHistory: true,
		
		  //Design
		  controlArrows: true,
		  verticalCentered: true,
		  sectionsColor : ['#ccc', '#fff'],
		  paddingTop: '3em',
		  paddingBottom: '10px',
		  fixedElements: 'none',
		  responsiveWidth: 0,
		  responsiveHeight: 0,
		  responsiveSlides: false,
		  parallax: false,
		  parallaxOptions: {type: 'reveal', percentage: 62, property: 'translate'},
		
		  //Custom selectors
		  sectionSelector: '.scrollable',
		  slideSelector: '.slide',
		
		  lazyLoading: true,
		
		  //events
		  onLeave: function(origin, destination, direction){
		  	
		  	$(".nav li").removeClass("active");
		  	var link = $(".nav li a[href='#" + destination.anchor + "']");
		  	link.parents('li').addClass('active');

		  },
		  afterLoad: function(origin, destination, direction){},
		  afterRender: function(){
			  startScrollReveal();
		  },
		  afterResize: function(width, height){},
		  afterResponsive: function(isResponsive){},
		  afterSlideLoad: function(section, origin, destination, direction){},
		  onSlideLeave: function(section, origin, destination, direction){}
		});
	}
});



if ($("body").hasClass("inner")) {
  $(document).ready(function() {
    $('#fullpage-inner').fullpage({
      licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
      scrollBar: false,
      responsiveWidth: 900
    });
  });
}

$(".burger-menu").click(function () {
    $(this).toggleClass("menu-on");       //Animate burger menu
    $('body').toggleClass("menu-open");   //Show menu content
    $('div.burger-background').fadeToggle(300);
    console.log('BURGER - Animation');
});

$(".burger-background").click(function () {
    $('.burger-menu').toggleClass("menu-on");       //Animate burger menu
    $('body').toggleClass("menu-open");   //Show menu content
    $('div.burger-background').fadeToggle(300);
    console.log('BURGER - Animation');
});

var iframeHref = $('iframe').attr('src');

$(".showreel-toggle").click(function (event) {
    event.preventDefault();       //Prevent href normal behavior
    $('.showreel').fadeIn(300);   //Reveal video container
    $('iframe').attr('src', iframeHref);  							  //Auto launch video after display
    console.log('SHOWREEL - Open & play');
});

$(".showreel").click(function () {
    $('.showreel').fadeOut(300);      //Hide showreel
    iframeHref = $('iframe').attr('src');
    $('iframe').attr('src', "" );      //Stop video
    console.log('SHOWREEL - Close & stop');
});

$(".next:not(.back)").click(function (event) {
    event.preventDefault();           //Prevent href normal behavior
    fullpage_api.moveSectionDown();   //Go to next VIEW
    console.log('SCROLL - Goto next section');
});

$('[data-pair]').on('mouseenter', 'li', function (e) {
	var hoverName = $(e.delegateTarget).attr('data-pair');
	var index = $(this).index();

	$('ul[data-pair="' + hoverName + '"] li:nth-of-type(' + (1+index) + ')').addClass('active-logo');

	$(this).one('mouseleave', function (e) {
		$('ul[data-pair="' + hoverName + '"] li:nth-of-type(' + (1+index) + ')').removeClass('active-logo');
	});
});

//

// $(document).ready(function() {

//  function noise() {
//           (function() {
//             "use strict";

//             var canvas = document.querySelector("#tv"),
//               context = canvas.getContext("gl") || canvas.getContext("2d"),
//               scaleFactor = 3, // Noise size
//               samples = [],
//               sampleIndex = 0,
//               scanOffsetY = 0,
//               scanSize = 0,
//               FPS = 50,
//               scanSpeed = FPS * 0, // 15 seconds from top to bottom
//               SAMPLE_COUNT = 10;

//             window.onresize = function() {
//               canvas.width = canvas.offsetWidth / scaleFactor;
//               canvas.height = canvas.width / (canvas.offsetWidth / canvas.offsetHeight);
//               scanSize = (canvas.offsetHeight / scaleFactor) / 3;

//               samples = []
//               for(var i = 0; i < SAMPLE_COUNT; i++)
//                 samples.push(generateRandomSample(context, canvas.width, canvas.height));
//             };

//             function interpolate(x, x0, y0, x1, y1) {
//               return y0 + (y1 - y0)*((x - x0)/(x1 - x0));
//             }


//             function generateRandomSample(context, w, h) {  
//               var intensity = [];
//               var random = 0;
//               var factor = h / 1;
//               var trans = 1 - Math.random() * 0;

//               var intensityCurve = [];
//               for(var i = 0; i < Math.floor(h / factor) + factor; i++)
//                 intensityCurve.push(Math.floor(Math.random() * 15));

//               for(var i = 0; i < h; i++) {
//                 var value = interpolate((i/factor), Math.floor(i / factor), intensityCurve[Math.floor(i / factor)], Math.floor(i / factor) + 1, intensityCurve[Math.floor(i / factor) + 1]);
//                 intensity.push(value);
//               }

//               var imageData = context.createImageData(w, h);
//               for(var i = 0; i < (w * h); i++) {
//                 var k = i * 4;
//                 var color = Math.floor(36 * Math.random());
//                 // Optional: add an intensity curve to try to simulate scan lines
//                 color += intensity[Math.floor(i / w)];
//                 imageData.data[k] = imageData.data[k + 1] = imageData.data[k + 2] = color;
//                 imageData.data[k + 3] = Math.round(255 * trans);
//               }
//               return imageData;
//             } 

//             function render() {
//               context.putImageData(samples[Math.floor(sampleIndex)], 0, 0);

//               sampleIndex += 20 / FPS; // 1/FPS == 1 second
//               if(sampleIndex >= samples.length) sampleIndex = 0;

//               var grd = context.createLinearGradient(0, scanOffsetY, 0, scanSize + scanOffsetY);

//               grd.addColorStop(0, 'rgba(255,255,255,0)');
//               grd.addColorStop(0.1, 'rgba(255,255,255,0)');
//               grd.addColorStop(0.2, 'rgba(255,255,255,0.2)');
//               grd.addColorStop(0.3, 'rgba(255,255,255,0.0)');
//               grd.addColorStop(0.45, 'rgba(255,255,255,0.1)');
//               grd.addColorStop(0.5, 'rgba(255,255,255,1.0)');
//               // grd.addColorStop(0.55, 'rgba(255,255,255,0.55)');
//               // grd.addColorStop(0.6, 'rgba(255,255,255,0.25)');
//               // grd.addColorStop(0.8, 'rgba(255,255,255,0.15)');
//               // grd.addColorStop(1, 'rgba(255,255,255,0)');

//               context.fillStyle = grd;
//               context.fillRect(0, scanOffsetY, canvas.width, scanSize + scanOffsetY);
//               context.globalCompositeOperation = "lighter";

//               scanOffsetY += (canvas.height / scanSpeed);
//               if(scanOffsetY > canvas.height) scanOffsetY = -(scanSize / 2);

//               window.requestAnimationFrame(render);
//             }
//             window.onresize();
//             window.requestAnimationFrame(render);
//           })();
//     }

//     function reload() {
//       console.log('SCROLL - Canvas is reloading');
//       $('canvas').removeAttr('id');
//       $('.active canvas').attr( 'id', 'tv' );
//       noise(); 
//     }

//     $(window).on('hashchange', function(e){
//       reload();
//     });

//     $(document).ready(function(){
//       reload();
//     });

// });

//

var lFollowX = 0,
    lFollowY = 0,
    x = 0,
    y = 0,
    friction = 1 / 30;

function moveBackground() {
  x += (lFollowX - x) * friction;
  y += (lFollowY - y) * friction;
  
  translate = 'translate(' + x + 'px, ' + y + 'px) scale(1.1)';

  $('.section-background-img').css({
    '-webit-transform': translate,
    '-moz-transform': translate,
    'transform': translate
  });

  window.requestAnimationFrame(moveBackground);
}

$(window).on('mousemove click', function(e) {

  var lMouseX = Math.max(-100, Math.min(100, $(window).width() / 2 - e.clientX));
  var lMouseY = Math.max(-100, Math.min(100, $(window).height() / 2 - e.clientY));
  lFollowX = (30 * lMouseX) / 100; // 100 : 12 = lMouxeX : lFollow
  lFollowY = (30 * lMouseY) / 100;

});

moveBackground();


// BURGER INNER

$('div.section div.section-inner div.container').on('mousewheel', function(event, delta) {

    var target = $( "div.container" );
    var offset = target.offset();
    var value =  offset.top;

    if(value < 130) {
    $('body').addClass('mini-burger');

    } 
    else {
    $('body').removeClass('mini-burger');
    }
});





$('.member:nth-child(1) h4').click(function(e){
    e.preventDefault();
	$('.member:nth-child(1)').find('p').slideToggle(300);
	$('.member:nth-child(1)').find('h4').toggleClass("transform-after");    
});

 //MAP GOOGLE

      function myMap() {
        var myLatLng = {lat: 50.827778, lng: 4.359558};

        var map = new google.maps.Map(document.getElementById('map-canvas'), {
          zoom: 18,
          center: myLatLng
        });

        var marker = new google.maps.Marker({
          position: myLatLng,
          map: map,
          title: 'Hello World!'
        });
      }



// var navHeight = $('header').height();
// var navInitialPos = $('header').offset().top;


// 	//if ($(document).width() > 1280) {
// 	$(document).scroll(headerPositionFixer);

// 	function headerPositionFixer () {
// 		if ($(document).scrollTop() > navInitialPos) {
// 			$('header').addClass('fixed');
// 		} else {
// 			$('header').removeClass('fixed');
// 		}
// 	}



$(document).ready(function(){
	
	$('.modal .close').click(function(){
		$('.modal').fadeOut(function(){
			$(this).remove();
		});
	});
});


// GDPR MODAL - COOKIE BAR 

$('.btn-choice ').click(function() {
  	$(this).parents('.category').toggleClass('active');
});


$('.cookie_infos').click(function() {
  	$(".modal-gdpr").addClass("show");
});

$('.close').click(function() {
  	$(".modal-gdpr").removeClass("show");
});


$('.open-cookie').click(function() {
  	$(".cookie-bar").removeClass("noshow");
  	return false;
});

var gdprGetParams = {};
$('.accept_cookie').click(function() {
  	$(".cookie-bar").addClass("noshow");
  	
	setCookie('_gdpr-settings', JSON.stringify(gdprGetParams) );

  	$.get( THEME_PATH + "/partials/analytics.php" , gdprGetParams, function(scripts){
  		$("body").append(scripts)
  	} ,"html"  );
  	
  	return false;
});

var setCookie = function(name, value) {
    var d = new Date;
    d.setTime(d.getTime() + (20 * 365 * 24 * 60 * 60));
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

$('.btn-save').click(function(){
		
		var categories = $('.category.active');
		categories.each(function(){
			var track = $(this).data('tracking');
			gdprGetParams[track] = 1;
		});
		
		$(".modal-gdpr").removeClass("show");

		return false;
}); 