$('select').each(function(){
    var $this = $(this), numberOfOptions = $(this).children('option').length;
  
    $this.addClass('select-hidden'); 
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="select-styled"></div>');

    var $styledSelect = $this.next('div.select-styled');
    $styledSelect.text($this.children('option').eq(0).text());
  
    var $list = $('<ul />', {
        'class': 'select-options'
    }).insertAfter($styledSelect);
  
    for (var i = 0; i < numberOfOptions; i++) {
        $('<li />', {
            text: $this.children('option').eq(i).text(),
            rel: $this.children('option').eq(i).val()
        }).appendTo($list);
    }
  
    var $listItems = $list.children('li');

    console.log($listItems)
  
    $styledSelect.click(function(e) {
        e.stopPropagation();
        $('div.select-styled.active').not(this).each(function(){
            $(this).removeClass('active').next('ul.select-options').hide();
        });
        $(this).toggleClass('active').next('ul.select-options').toggle();
    });
  
    $listItems.click(function(e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass('active');
        $this.val($(this).attr('rel'));
        $list.hide();
        //console.log($this.val());

        searchInMovies()
    });
  
    $(document).click(function() {
        $styledSelect.removeClass('active');
        $list.hide();
    });

});

var searchInMovies = function(){
	
	ScrollReveal().destroy();
	
	var filter = $('input[name="search"]').val();
	var formatId = parseInt($('select[name="format"]').val());
	var catId = parseInt($('select[name="category"]').val());
	
	var pillarsIds = [];
	$('input.pillar:checked').each(function(){
		pillarsIds.push(parseInt($(this).val()));
	});
		
	if(filter.trim().length <= 0 && !catId && !formatId && pillarsIds.length == 0 ) {
		$('.content-container-films a').show();
		return;
	}
		
	var moviesIds = [];
	$('.content-container-films a').each(function(){
		moviesIds.push($(this).attr('id'));
	}); 
	
	if(moviesIds.length > 0) {	
		
		var mid, movieBlock;
			
		if(catId > 0) {
			for(var o in moviesIds) {
				movieBlock = $('#' + moviesIds[o]);
				var categories = movieBlock.data('category');
				if(!categories || categories.indexOf(catId) == -1) {
					delete moviesIds[o];
				}
			}
		}
		
		if(formatId > 0) {
			for(var o in moviesIds) {
				movieBlock = $('#' + moviesIds[o]);
				var formats = movieBlock.data('format');
				if(!formats || formats.indexOf(formatId) == -1) {
					delete moviesIds[o];
				}
			}
		}
		
		if( pillarsIds.length > 0 ) {
			for(var o in moviesIds) {
				movieBlock = $('#' + moviesIds[o]);
				var pillars = movieBlock.data('pillars');
				
				if(pillars !== undefined) {
					
					if(pillars.length == 0){
						delete moviesIds[o];
					} else {
						
						var checks = [];
						
						for(var pilID in pillars){
							var pillar = pillars[pilID];
							
							for(var p in pillarsIds){
								var pid = pillarsIds[p];
								if( parseInt(pid) == parseInt(pillar) ){
									checks.push(pid);
								}
							}
						}
						
						if(checks.length < pillarsIds.length) {
							 delete moviesIds[o];
						}
					}					
				}
			}
		}
		
		if( filter.trim().length > 0 ) {
			
			for(var u in moviesIds) {
				
				if(undefined === moviesIds[u]) continue;
				
				movieBlock = $('#' + moviesIds[u]);
				var actors = movieBlock.data('actors');
				var title = movieBlock.find('li').text().toLowerCase();
				
				if( typeof actors == "string" ) {
					try {
						actors = JSON.parse(actors);
					} catch(e){}
					if( typeof actors == "string" ) {
						actors = actors.split(',');
					}
				}
					
				var valids = actors.filter(function(item){
					return item.toLowerCase().indexOf(filter.toLowerCase().trim()) >= 0;
				});
				
				if(undefined != title && title.indexOf(filter.toLowerCase().trim()) > -1 ) {
					valids.push(title);
				}
					
				if(valids.length == 0) {
					mid = movieBlock.attr('id');
					delete moviesIds[u];
				}
				
			}
		}
		
		$('.content-container-films a').hide();
		for(var i in moviesIds){
			if(moviesIds[i]) {
				var m = $('#' + moviesIds[i]);
				//console.log(pillarsIds, m.data('pillars'));
				m.show();
			}
		} 
	}	
};

$(document).ready(function(){
	
	if( $('.content-container-search').length > 0 ) {
		
		$('input[name="search"]').keyup(searchInMovies);		
		$('select[name="format"]').change(searchInMovies);
		$('select[name="category"]').change(searchInMovies);
		$('input.pillar').change(function(){
			searchInMovies();
		});		
	}	
});